import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby";
import Header from "../components/header"
import Layout from "../components/layouts/layout"
import ProjectCard from "../components/projects/projectCard"

export default function CategoryTemplate({ pageContext, data }) {
  const { cat } = pageContext;
  const posts = data.allMarkdownRemark.edges;
  const title = data.site.siteMetadata.title;
  const uppercaseCat = cat.toString().toUpperCase();
  return (
    <Layout>
      <SEO
        title={`Posts in "${cat}" category | ${title}`}
        description={`Posts in "${cat}" category`}
      />
      <section className="container section">
      <Header/>
      <div style={{minHeight: "50vh"}}>

        <h3 className="title is-4 has-text-grey-dark">{`PROJECTS IN "${uppercaseCat}" CATEGORY`}</h3>
        <div className="columns is-multiline is-variable is-2-mobile is-3-tablet is-3-desktop is-3-widescreen is-3-fullhd">

          {posts.map(node => {
            console.log(node);
            return (
              <div className="column is-one-third-desktop">
                <ProjectCard node={node}/>
              </div>
            )
          })}
        </div>
        </div>
      </section>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($cat: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$cat] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            category
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`