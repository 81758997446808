import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import SiteConfig from "../../../data/SiteConfig"

const _ = require("lodash");

export default function ProjectCard({node}) {

  console.log(node);

  return (
    <div>
      <Link to={node.node.fields.slug}>
        <div className="title is-6 has-text-grey-dark mb-0">{node.node.frontmatter.title}</div>
        <span><time dateTime="2016-1-1" className="is-size-7 has-text-grey-dark">{node.node.frontmatter.date}</time></span>
      </Link>
      <div className="mb-3" style={{maxHeight: 180, overflow: "hidden"}}>
        <Link to={node.node.fields.slug}>
        <figure className="image">
          <Img fluid={node.node.frontmatter.featuredImage.childImageSharp.fluid}/>
        </figure>
        </Link>
      </div>
      <div className="mt-2">{node.node.frontmatter.category.map((cat) => (
        <Link to={`/category/${_.kebabCase(cat.toString())}`}><span key={`${cat}`} className="tag mr-1">{cat.toUpperCase()}</span></Link>
      ))}
      </div>
      <div className="is-size-7 has-text-grey-dark mt-2"
        dangerouslySetInnerHTML={{
          __html: node.node.frontmatter.description
        }}/>
      <div style={{lineHeight: "1.25em"}}><Link to={node.node.fields.slug} className="is-size-7" style={{color: SiteConfig.themeColors.accent}}>{node.node.frontmatter.title} project page</Link></div>

      <hr className="is-hidden-tablet"/>
    </div>
  )

}